// @flow
import React from 'react';
import IntroBlock from '../shared/IntroBlock';

const Intro = () => (
  <div id="intro">
    <IntroBlock
      title="導入のポイント"
      text="ネットショップの成功の秘訣は、ユーザーのニーズに合わせた『決済』を用意すること。<br/>
            ペイディは、「いま買いたい！でも支払いは後がいい」というユーザーニーズに応えます。
            <br/>
            <br/>
            ユーザーが支払いに必要なのはメールアドレスと携帯電話番号だけ。<br/>
            決済のフローをシンプルにすれば、ショップの機会損失減少や、売上アップも期待できます！<br/>
            さらに、未払い債権はペイディが負担する「売上金100％保証」で未回収リスクがなくなる事、<br/>
            初期費用、月額費用が0円でかかる費用は決済手数料のみ、というのも見逃せないポイント。<br/>
            御社のサイトにペイディを導入しませんか？"
    />
  </div>
);

export default Intro;
