// @flow
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { blocks } from './const';
import Company from '../shared/Company';
import Intro from './Intro';
import CaseStudies from '../shared/CaseStudies';
import Start from '../shared/Start';
import NavigationBlock from '../shared/NavigationBlock';
import { getMerchantsIds, getCasesIds } from '../utils';
import * as MerchantActions from '../../../redux/merchant/actions';
import Merchants from '../shared/Merchants';
import { navigationStyles } from './styles';

const Navigation = () => {
  const goodsPage = useSelector((state) => state.merchantPages.goodsPage);
  const dispatch = useDispatch();
  const classes = navigationStyles({});

  // Did mount
  useEffect(() => {
    dispatch(MerchantActions.getPageGoods());
  }, [dispatch]);

  const merchantsIds = getMerchantsIds(goodsPage);
  const casesIds = getCasesIds(goodsPage);

  return (
    goodsPage && (
      <NavigationBlock blocks={blocks}>
        <Company
          title="ファッションや食品、家電などの一般的なモノからフリマまで幅広くお使いいただけます。"
          subTitle="導入企業"
        >
          {merchantsIds && (
            <>
              <div className={classes.pc}>
                <Merchants
                  perRow={4}
                  amount={4}
                  merchantsIds={merchantsIds}
                  logoStyles={{ background: 'white' }}
                />
              </div>
              <div className={classes.sp}>
                <Merchants
                  perRow={2}
                  amount={4}
                  merchantsIds={merchantsIds}
                  logoStyles={{
                    background: 'white',
                    width: '160px',
                    height: '50px',
                  }}
                />
              </div>
            </>
          )}
        </Company>
        <Intro />
        <CaseStudies casesIds={casesIds} />
        <Start />
      </NavigationBlock>
    )
  );
};

export default Navigation;
